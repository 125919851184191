import React from 'react'

export default function ThemeBased() {
  return (
    <>
  {/* banner section  */}
  <section className="banner-section">
    <div className="banner">
      <img src="assets/images/theme-banner.jpg" alt="" width="100%" />
    </div>
  </section>
  {/* banner section end  */}
  {/* special travels area start  */}
  <section className="special-travels pt-5 pb-5">
    <div className="container-fluid">
      <div className="title">
        <h2>Our Travel Specialization</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="row mt-5">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/1.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Honeymoon Tour</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/2.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Leasure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/3.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Trekking</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/4.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Adventure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/5.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Hill Station</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/6.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Yoga</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* special travels area end  */}
  {/*international destination area start  */}
  <section className="destination-international">
    <div className="container-fluid">
      <div className="title">
        <h2>Our Best Tour Destination</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="intern-boxes mt-5">
        <div className="slider">
          <div className="owl-carousel owl-theme" id="owl-theme-slide">
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/himachal.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Himachal Tour </h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/uttarakhand.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Uttarakhand Tour </h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/goa.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5>Goa Tour </h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/rajasthan.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Rajasthan Tour </h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/kashmir.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Kashmir Tour</h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/kerala.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Kerala Tour</h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="item">
              <div className="intern-box">
                <a href="#">
                  <div className="destination-img">
                    <img
                      src="assets/images/dome-pack-inner/tamil.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="inter-text">
                    <h5> Tamil Nadu</h5>
                    <p>15 Tour Package</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* destination area start  */}
</>

  )
}
