import React from 'react'

export default function GuestFeedback() {
  return (
    <>
  {/* banner section  */}
  <section className="banner-section">
    <div className="banner">
      <img src="assets/images/feedback-banner.jpg" alt="" width="100%" />
    </div>
  </section>
  {/* banner section end  */}
  <section className="contact pb-5">
    <div className="container-fluid p-0">
      <div className="row g-0">
        <div className="col-md-5">
          <div className="feedback-box p-2">
            <div className="feedback-box-title">
              <div className="Feedback-title pt-5">
                <h4 className="text-center">Your Feedback Matters to Us!</h4>
              </div>
            </div>
            <div className="feedback-content mt-3 ">
              <p>
                At Minto Holidays, your feedback is essential to crafting
                unforgettable travel experiences. Every review, suggestion, and
                comment helps us understand what you loved and where we can
                improve. Your insights guide us in enhancing our tour packages,
                customer service, and the overall travel experience. We’re
                dedicated to ensuring that each trip is better than the last,
                tailored perfectly to your needs and desires. By sharing your
                thoughts, you help us grow and adapt to serve you better. Thank
                you for being an essential part of our journey—your opinion
                shapes our adventures!
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-7 " id="feedbox-form-box">
          <div className="contact-page-form pt-5" id="feedbox-form-box">
            <div className="title">
              <h3 className="text-justify" id="feedback-title">
                Send Your Feedback
              </h3>
            </div>
            <form className="mt-4" action="">
              <div className="row">
                <div className="mb-3 col-6 contact-input">
                  <i className="fa fa-user-o" />
                  <input
                    type="text"
                    className="form-control"
                    id="Name"
                    placeholder="Enter Full Name *"
                  />
                </div>
                <div className="mb-3 col-6 contact-input">
                  <i className="fa fa-volume-control-phone" />
                  <input
                    type="tel"
                    className="form-control"
                    id="Phone"
                    placeholder="Enter Phone No. *"
                  />
                </div>
                <div className="mb-3 col-6 contact-input">
                  <i className="fa fa-envelope-o" />
                  <input
                    type="email"
                    className="form-control"
                    id="Email"
                    placeholder="Enter Email Id *"
                  />
                </div>
                <div className="mb-3 col-6 contact-input">
                  <i className="fa fa-map-marker" />
                  <input
                    type="text"
                    className="form-control"
                    id="text"
                    placeholder="Enter City Tour *"
                  />
                </div>
                <div className="mb-3 col-12 contact-input">
                  <i className="fa fa-pencil-square-o" />
                  <textarea
                    className="form-control"
                    placeholder="Enter Your Feedback"
                    id="floatingTextarea2"
                    style={{ height: 100 }}
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className="more-details-btn">
                <button className="btn btn-more-detaisl">
                  <i className="fa fa-send-o" /> Submit Your Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="all-feedbacks py-5">
    <div className="container-fluid px-lg-5">
      <div className="title">
        <h2>Best Feedbacks</h2>
      </div>
      <div className="row mt-5">
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-green">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p className="feedback-content">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione
                <span className="hidden-content">
                  ullam unde odio, sed optio itaque iste magnam blanditiis dicta
                  illo eum.{" "}
                </span>
              </p>
              <button className="read-more-btn">Read More... </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-yellow">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione
                <span className="hidden-content">
                  ullam unde odio, sed optio itaque iste magnam blanditiis dicta
                  illo eum.{" "}
                </span>
              </p>
              <button className="read-more-btn">Read More... </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-orange">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione
                <span className="hidden-content">
                  ullam unde odio, sed optio itaque iste magnam blanditiis dicta
                  illo eum.
                </span>
              </p>
              <button className="read-more-btn">Read More... </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-green">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-yellow">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-orange">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-green">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-yellow">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <div className="all-feedback-box" id="bg-orange">
            <div className="client-img">
              <div className="user-img">
                <div className="user-icon">
                  <img
                    src="assets/images/icons/users.jpg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="user-name">
                  <h5>Ethan Mitchell</h5>
                  <span>Students</span>
                </div>
              </div>
              <div className="stars py-2">
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
            <div className="review-para pt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                velit officia ratione ullam unde odio, sed optio itaque iste
                magnam blanditiis dicta illo eum.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pagignation-box">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item ">
              <a className="page-link end" href="#">
                <i className="fa fa-angle-left" />
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                4
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                ....
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="fa fa-angle-right" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</>

  )
}
