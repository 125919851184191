import React from 'react'

export default function Slider() {
  return (
    <>
        {/* banner section  */}
  <section className="banner-section">
    <div
      id="carouselExampleFade"
      className="carousel slide carousel-fade desktop-banner"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="assets/images/banner.jpg" className="d-block w-100" alt="..." />
          <div className="banner-content">
            <div className="container-fluid">
              <div className="banner-title">
                <h5>Let's Travel</h5>
                <h2>Family Tour</h2>
                <h3>35% Off</h3>
                <p>
                  Whatever Your Summer Looks Like, Bring Yourown Heat With Up To
                  35% Off!
                </p>
                <a href="#">Book A Trip</a>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="assets/images/banner-test.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="banner-content">
            <div className="container-fluid">
              <div className="banner-title">
                <h5>Let's Travel</h5>
                <h2>Family Tour</h2>
                <h3>35% Off</h3>
                <p>
                  Whatever Your Summer Looks Like, Bring Yourown Heat With Up To
                  35% Off!
                </p>
                <a href="#">Book A Trip</a>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="assets/images/banner-test-2.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="banner-content">
            <div className="container-fluid">
              <div className="banner-title">
                <h5>Let's Travel</h5>
                <h2>Family Tour</h2>
                <h3>35% Off</h3>
                <p>
                  Whatever Your Summer Looks Like, Bring Yourown Heat With Up To
                  35% Off!
                </p>
                <a href="#">Book A Trip</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    <div
      id="carouselExampleAutoplaying"
      className="carousel slide carousel-fade mobile-banner"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src="assets/images/mobile-banner.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="banner-content"></div>
        </div>
        <div className="carousel-item">
          <img
            src="assets/images/mobile-banner-2.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="banner-content"></div>
        </div>
        <div className="carousel-item">
          <img
            src="assets/images/mobile-banner-3.jpg"
            className="d-block w-100"
            alt="..."
          />
          <div className="banner-content"></div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    <div className="search-form">
      <div className="search-form-box">
        <div className="form">
          <div className="form-inputs">
            <div className="search-form-input-1" id="first-select">
              <i className="fa fa-map-marker" />
              <select id="Cityselect">
                <option value="disable">Select City</option>
                <option value="AL">Uttarakhand</option>
                <option value="WY">Himachal</option>
                <option value="WY">Kashmir</option>
                <option value="WY">Kerala</option>
                <option value="WY">Goa</option>
                <option value="WY">Rajasthan</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="search-form-input-2" id="second-select">
              <i className="fa fa-flag-o" />
              <select id="Tourselect">
                <option value="disable">Select Your Tour</option>
                <option value="AL">Honeymoon Tour Package</option>
                <option value="WY">Car Tour Package</option>
                <option value="WY">Couple Tour package</option>
                <option value="WY">Family Tour Package</option>
                <option value="WY">Group Tour Package</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="search-form-button">
              <button className="btn btn-search text-light">
                Search <i className="fa fa-send-o" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* banner section end  */}
    </>
  )
}
