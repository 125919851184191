import React from 'react'

export default function Footer() {

  return (
   <>

<div
  className="modal fade"
  id="exampleModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-lg">
    <div className="modal-content">
      <div className="modal-header" id="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel" />
        <button
          type="button"
          className="btn-close modal-button-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="row">
        <div className="col-lg-6" id="mobile-modal-hide">
          <div className="modal-body">
            <div className="modal-banner-content">
              <div className="modal-banner">
                <div
                  id="carouselExampleRide"
                  className="carousel slide carousel-fade"
                  data-bs-ride="true"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <a href="#">
                        <img
                          src="images/modal-banner/1.jpg"
                          className="d-block w-100"
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="carousel-item">
                      <a href="#">
                        <img
                          src="images/modal-banner/2.jpg"
                          className="d-block w-100"
                          alt="..."
                        />
                      </a>
                    </div>
                    <div className="carousel-item">
                      <a href="#">
                        <img
                          src="images/modal-banner/3.jpg"
                          className="d-block w-100"
                          alt="..."
                        />
                      </a>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleRide"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleRide"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="modal-features">
                <div className="why-features row py-3">
                  <div className="features-box col-4">
                    <div className="features-box-icon">
                      <img
                        src="images/icons/traveler.png"
                        alt=""
                        width="45px"
                      />
                    </div>
                    <div className="features-box-name pl-2">
                      <h4>60K+</h4>
                      <span>Happy Traveler</span>
                    </div>
                  </div>
                  <div className="features-box col-4" id="features-box-border">
                    <div className="features-box-icon">
                      <img src="images/icons/loyalty.png" alt="" width="45px" />
                    </div>
                    <div className="features-box-name pl-2">
                      <h4>60K+</h4>
                      <span>Tours Success</span>
                    </div>
                  </div>
                  <div className="features-box col-4">
                    <div className="features-box-icon">
                      <img
                        src="images/icons/experience.png"
                        alt=""
                        width="45px"
                      />
                    </div>
                    <div className="features-box-name pl-2">
                      <h4>60K+</h4>
                      <span>Positives Review </span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="last-line">
                  <p className="text-center">
                    <b>Excellent!</b>{" "}
                    <span>
                      {" "}
                      <i className="fa fa-circle" />{" "}
                      <i className="fa fa-circle" />{" "}
                      <i className="fa fa-circle" />{" "}
                      <i className="fa fa-circle" />{" "}
                      <i className="fa fa-circle-o" />{" "}
                    </span>{" "}
                    4.5 Rating Out of 5.0 Based On 526 reviews.
                  </p>
                </div>
              </div>
              <div className="modal-contact">
                <div className="call-modal">
                  <h6>
                    <a href="">
                      <span>
                        <i className="fa fa-phone" /> Call Us :{" "}
                      </span>{" "}
                      +91-1234567890
                    </a>
                  </h6>
                  <h6>
                    <a href="">
                      <span>
                        <i className="fa fa-envelope" /> Send Us Mail :{" "}
                      </span>{" "}
                      info@mintoholidays.com
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6" id="pop-right">
          <div className="modal-body" id="right-body">
            <div className="popup-form">
              <h5>Get in touch with us</h5>
              <form action="">
                <div className="col mb-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="col mb-3">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder="Enter Phone no."
                  />
                </div>
                <div className="col mb-3">
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Enter Email Id."
                  />
                </div>
                <div className="col mb-3">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    placeholder="Enter Tour Details"
                    defaultValue={""}
                  />
                </div>
                <div className="col- mb-3">
                  <button className="btn btn-submit">Submit Enquiry</button>
                  {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                </div>
              </form>
            </div>
            <div className="get-whatsapp">
              <a href="">
                <span>
                  <img src="images/icons/whatsapp.png" alt="" />
                </span>
                <h6>Get itenary On Whatsapp</h6>
              </a>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <div className="quick_contact">
    <a
      href="https://api.whatsapp.com/send?phone=#&text=hello"
      className="qwhtsapp"
      target="_blank"
    >
      <i className="fa fa-whatsapp quick_contact_icon" />
    </a>
    <a href="#" className="qcall" target="_blank">
      <i className="fa fa-phone quick_contact_icon" />
    </a>
  </div>
  <div className="qiuck-enquiry">
    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
      Enquiry Now
    </button>
  </div>


    <footer className="pt-5">
    <div className="container-fluid px-lg-5">
      <div className="footer-top pb-4">
        <div className="footer-logo">
          <a href="index.html">
            <img src="assets/images/logo-white.png" alt="" width="250px" />
          </a>
        </div>
        <div className="rating py-2">
          <p>
            <span>
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
              <i className="fa fa-star" />
            </span>{" "}
            4.9 rating | 600 Customer review.
          </p>
        </div>
        <div className="language-select py-2">
          <span>
            <img src="assets/images/icons/globe.png" alt="" />
          </span>
          <div className="language">
            <select>
              <option value="default">English</option>
              <option>Hindi</option>
            </select>
          </div>
        </div>
      </div>
      <div className="footer-bottom py-5">
        <div className="row">
          <div className="col-lg-2 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Contact Info</h5>
              </div>
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="#">2972 Wetern Rd. Santa Ana, lllinois 85486</a>
                  </li>
                  <li>
                    <h5>
                      <a href="#">+8134 123 456 789</a>{" "}
                    </h5>
                  </li>
                  <li>
                    <h5>
                      <a href="#">+8134 123 456 789</a>{" "}
                    </h5>
                  </li>
                  <li>
                    <a href="#">Support@example.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Our Company</h5>
              </div>
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="about-us.html">About us</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">News and Blogs</a>
                  </li>
                  <li>
                    <a href="#">Support Policy</a>
                  </li>
                  <li>
                    <a href="#">Shop By Location</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Quick Links</h5>
              </div>
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="#">View Account</a>
                  </li>
                  <li>
                    <a href="#">Track Your Order</a>
                  </li>
                  <li>
                    <a href="#">Return/Exchange</a>
                  </li>
                  <li>
                    <a href="#">Promotions</a>
                  </li>
                  <li>
                    <a href="#">Customer Reviews</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Tour Destinations</h5>
              </div>
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="#">Himachal Pradesh</a>
                  </li>
                  <li>
                    <a href="#">Uttarakhand</a>
                  </li>
                  <li>
                    <a href="#">Uttar Pradesh</a>
                  </li>
                  <li>
                    <a href="#">Goa</a>
                  </li>
                  <li>
                    <a href="#">Rajasthan</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="footer-details">
              <div className="footer-link-title">
                <h5>Our Newsletter</h5>
              </div>
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="#">
                      Save up to 50% on tours! Get exclusive access to members
                      only deal by email
                    </a>
                  </li>
                  <div className="newsletter-input">
                    <input type="text" placeholder="Email address" />
                    <button>
                      <i className="fa fa-arrow-right " /> Subscribe
                    </button>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-section py-4">
        <div className="copyright">
          <p>
            Copyright © 2024 <a href="index.html">Mintoholidays.</a> All Right
            reserved
          </p>
        </div>
        <div className="footer-follow-link">
          <span>
            <a href="#">
              <i className="fa fa-facebook" />
            </a>
            <a href="#">
              <i className="fa fa-twitter" />
            </a>{" "}
            <a href="#">
              <i className="fa fa-instagram" />
            </a>{" "}
            <a href="#">
              <i className="fa fa-pinterest" />
            </a>
            <a href="#">
              <i className="fa fa-youtube-play" />
            </a>
          </span>
        </div>
        <div className="payments">
          <img src="assets/images/icons/payment.png" alt="" />
        </div>
      </div>
    </div>
  </footer>
  <div className="footer-pages">
    <div className="container-fluid px-lg-5 pt-4">
      <div className="footer-pages-link">
        <ul>
          <li>
            <a href="term&condition.html">Terms and Conditions </a>
          </li>
          <li>
            <a href="privacy-poicy.html">Privacy Policy </a>
          </li>
          <li>
            <a href="#">Manage Cookies </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
   </>
  )
}
