import React from 'react'

export default function FixedDepartures() {
  return (
    <>
  {/* banner section  */}
  <section className="banner-section">
    <div className="banner">
      <img src="assets/images/banner-inner.jpg" alt="" width="100%" />
    </div>
    {/* search form start  */}
    <div className="search-form">
      <div className="search-form-box">
        <div className="form">
          <div className="form-inputs">
            <div className="search-form-input-1" id="first-select">
              <i className="fa fa-map-marker" />
              <select id="Cityselect">
                <option value="disable">Select City</option>
                <option value="AL">Uttarakhand</option>
                <option value="WY">Himachal</option>
                <option value="WY">Kashmir</option>
                <option value="WY">Kerala</option>
                <option value="WY">Goa</option>
                <option value="WY">Rajasthan</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="search-form-input-2" id="second-select">
              <i className="fa fa-flag-o" />
              <select id="Tourselect">
                <option value="disable">Select Your Tour</option>
                <option value="AL">Honeymoon Tour Package</option>
                <option value="WY">Car Tour Package</option>
                <option value="WY">Couple Tour package</option>
                <option value="WY">Family Tour Package</option>
                <option value="WY">Group Tour Package</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="search-form-button">
              <button className="btn btn-search text-light">
                Search <i className="fa fa-send-o" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* search form end  */}
  </section>
  {/* banner section end  */}
  <section className="page-titles">
    <div className="container-fluid ">
      <div className="page-head px-lg-3">
        <h1>Our Fixed Departure Packages</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere sit
          quia atque, rem, quis ex deserunt, accusantium reiciendis pariatur
          quam consequuntur fugit officiis rerum quae. Fuga, sint dignissimos.
          Alias, assumenda omnis. Eius, voluptates saepe, porro incidunt at
          itaque deleniti labore modi aliquid ea culpa odit quidem, vitae
          nostrum sunt impedit dolorem. Earum sapiente atque consequuntur optio.
          Doloremque facilis quaerat totam.
        </p>
      </div>
    </div>
  </section>
  <section className="details-package">
    <div className="container-fluid ">
      <div className="details-package-inner">
        <div className="row">
          <div className="col-lg-3">
            <div className="filter-button fixed-bottom d-lg-none">
              <button id="filterBtn" className="btn btn-primary">
                Apply Filter <i className="fa fa-filter" />
              </button>
            </div>
            <div
              className="filter-dropup filter-boxes-mobile "
              id="filterDropUp"
            >
              <div className="filter-content">
                <button id="closeBtn" className="close-icon">
                  ×
                </button>
                <div className="filter-container">
                  <div className="dropdown-container" data-filter-id={3}>
                    <div className="dropdown-button">
                      <h5>Budget (per person)</h5>
                      <span className="arrow">
                        <i className="fa fa-angle-up" />
                      </span>
                    </div>
                    <div className="dropdown-menu open">
                      <div id="range-one" className="range-container">
                        <div className="sliders_control">
                          <input
                            type="range"
                            className="dual-range left-range"
                            min={0}
                            max={1500}
                            defaultValue={0}
                            step={1}
                          />
                          <input
                            type="range"
                            className="dual-range right-range"
                            min={0}
                            max={1500}
                            defaultValue={1500}
                            step={1}
                          />
                        </div>
                        <div className="numbers">
                          <div className="start">
                            <i className="fa fa-inr" />
                            <span>0</span>
                          </div>
                          <div className="end">
                            <i className="fa fa-inr" />
                            <span>1500</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-container">
                  <div className="dropdown-container" data-filter-id={1}>
                    <div className="dropdown-button">
                      <h5> Cities</h5>
                      <span className="arrow">
                        <i className="fa fa-angle-up" />
                      </span>
                    </div>
                    <div className="dropdown-menu open">
                      <input
                        type="text"
                        className="search-box"
                        placeholder="Search city..."
                      />
                      <div className="checkbox-group">
                        <div className="check-box-group-input">
                          <label>
                            <input type="checkbox" defaultValue="Delhi" /> Delhi
                          </label>
                          <span className="input-number">(113)</span>
                        </div>
                        <div className="check-box-group-input">
                          <label>
                            <input type="checkbox" defaultValue="Mumbai" />{" "}
                            Mumbai
                          </label>
                          <span className="input-number">(3)</span>
                        </div>
                        <div className="check-box-group-input">
                          <label>
                            <input type="checkbox" defaultValue="Kolkata" />{" "}
                            Kolkata
                          </label>
                          <span className="input-number">(3)</span>
                        </div>
                        <div className="check-box-group-input">
                          <label>
                            <input type="checkbox" defaultValue="Chennai" />{" "}
                            Chennai
                          </label>
                          <span className="input-number">(2)</span>
                        </div>
                        <span className="hidden-content">
                          <div className="check-box-group-input">
                            <label>
                              <input type="checkbox" defaultValue="Bangalore" />{" "}
                              Bangalore
                            </label>
                            <span className="input-number">(1)</span>
                          </div>
                        </span>
                        <button className="read-more-btn">Read More </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="filter-container">
                  <div className="dropdown-container" data-filter-id={2}>
                    <div className="dropdown-button">
                      <h5>Themes</h5>
                      <span className="arrow">
                        <i className="fa fa-angle-up" />
                      </span>
                    </div>
                    <div className="dropdown-menu open">
                      <div className="checkbox-group">
                        <div className="check-box-group-input">
                          <label>
                            <input type="checkbox" defaultValue="Delhi" />{" "}
                            Honeymoon
                          </label>
                          <span className="input-number">(20)</span>
                        </div>
                        <div className="check-box-group-input">
                          <label>
                            <input type="checkbox" defaultValue="Mumbai" />{" "}
                            Culture
                          </label>
                          <span className="input-number">(11)</span>
                        </div>
                        <div className="check-box-group-input">
                          <label>
                            <input type="checkbox" defaultValue="Kolkata" />{" "}
                            Offbeat
                          </label>
                          <span className="input-number">(11)</span>
                        </div>
                        <div className="check-box-group-input">
                          <label>
                            <input type="checkbox" defaultValue="Chennai" />{" "}
                            Adventure
                          </label>
                          <span className="input-number">(9)</span>
                        </div>
                        <span className="hidden-content">
                          <div className="check-box-group-input">
                            <label>
                              <input type="checkbox" defaultValue="Bangalore" />{" "}
                              Wildlife
                            </label>
                            <span className="input-number">(1)</span>
                          </div>
                        </span>
                        <button className="read-more-btn">Read More </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="offer-img">
                  <img src="assets/images/inner-page-offer.png" alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="inner-page-box">
              <div className="row">
                <div className="col-md-4">
                  <div className="inner-packages-image">
                    <div className="promotion-tag">
                      <span>Promotion</span>
                    </div>
                    <img
                      src="assets/images/inner-package/1-inner-page.jpg"
                      alt=""
                      width="100%"
                    />
                    <div className="inner-package-img-overlay">
                      <h6>Explore &amp; Adventure</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="package-incluision-box">
                    <div className="inner-package-date">
                      <span>5 Night / 6 Days</span>
                    </div>
                    <div className="inner-package-name">
                      <h4>Royal Rajasthan Tour Package</h4>
                    </div>
                    <div className="inner-package-dest">
                      <p>
                        <b> Destination: </b> Arrive
                        Jaipur-Ajmer-pushkar-jodhpur-Mount Abu-Udaipur Departure{" "}
                      </p>
                    </div>
                    <div className="inner-package-rating">
                      <span>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star half-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        (1,355 reviews)
                      </span>
                    </div>
                    <div className="package-itenary">
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/lunch.png" alt="" />
                          <div className="itneray-name">
                            <span>meals</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/bed.png" alt="" />
                          <div className="itneray-name">
                            <span>Hotel</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/tranfer.png" alt="" />
                          <div className="itneray-name">
                            <span>Transfer</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/sightdeeing.png" alt="" />
                          <div className="itneray-name">
                            <span>Sightseeing</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="package-pricing-box">
                    <div className="del-price">
                      <del>
                        {" "}
                        <h4>
                          <i className="fa fa-inr" /> 32,790
                        </h4>{" "}
                      </del>
                    </div>
                    <div className="main-price">
                      <h4>
                        <i className="fa fa-inr" /> 24,790
                      </h4>
                    </div>
                    <div className="price-para">
                      <span> Per Person On Twin Sharing</span>
                    </div>
                    <div className="buttons-group">
                      <div className="deatails-btn">
                        <a
                          href="fixed-departures-inner.html"
                          className="btn btn-details"
                        >
                          {" "}
                          View Details
                        </a>
                        <a
                          href="check-out-page.html"
                          className="btn btn-enquiry"
                        >
                          {" "}
                          Book Now
                        </a>
                      </div>
                      <div className="contact-btn">
                        <button className="btn btn-call"> Call</button>
                        <button className="btn btn-whatsapp"> Whatsapp</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="inner-page-box">
              <div className="row">
                <div className="col-md-4">
                  <div className="inner-packages-image">
                    <div className="promotion-tag">
                      <span>Promotion</span>
                    </div>
                    <img
                      src="assets/images/inner-package/1-inner-page.jpg"
                      alt=""
                      width="100%"
                    />
                    <div className="inner-package-img-overlay">
                      <h6>Explore &amp; Adventure</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="package-incluision-box">
                    <div className="inner-package-date">
                      <span>5 Night / 6 Days</span>
                    </div>
                    <div className="inner-package-name">
                      <h4>Royal Rajasthan Tour Package</h4>
                    </div>
                    <div className="inner-package-dest">
                      <p>
                        <b> Destination: </b> Arrive
                        Jaipur-Ajmer-pushkar-jodhpur-Mount Abu-Udaipur Departure{" "}
                      </p>
                    </div>
                    <div className="inner-package-rating">
                      <span>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star half-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        (1,355 reviews)
                      </span>
                    </div>
                    <div className="package-itenary">
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/lunch.png" alt="" />
                          <div className="itneray-name">
                            <span>meals</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/bed.png" alt="" />
                          <div className="itneray-name">
                            <span>Hotel</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/tranfer.png" alt="" />
                          <div className="itneray-name">
                            <span>Transfer</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/sightdeeing.png" alt="" />
                          <div className="itneray-name">
                            <span>Sightseeing</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="package-pricing-box">
                    <div className="del-price">
                      <del>
                        {" "}
                        <h4>
                          <i className="fa fa-inr" /> 32,790
                        </h4>{" "}
                      </del>
                    </div>
                    <div className="main-price">
                      <h4>
                        <i className="fa fa-inr" /> 24,790
                      </h4>
                    </div>
                    <div className="price-para">
                      <span> Per Person On Twin Sharing</span>
                    </div>
                    <div className="buttons-group">
                      <div className="deatails-btn">
                        <a
                          href="fixed-departures-inner.html"
                          className="btn btn-details"
                        >
                          {" "}
                          View Details
                        </a>
                        <a
                          href="check-out-page.html"
                          className="btn btn-enquiry"
                        >
                          {" "}
                          Book Now
                        </a>
                      </div>
                      <div className="contact-btn">
                        <button className="btn btn-call"> Call</button>
                        <button className="btn btn-whatsapp"> Whatsapp</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="inner-page-box">
              <div className="row">
                <div className="col-md-4">
                  <div className="inner-packages-image">
                    <div className="promotion-tag">
                      <span>Promotion</span>
                    </div>
                    <img
                      src="assets/images/inner-package/1-inner-page.jpg"
                      alt=""
                      width="100%"
                    />
                    <div className="inner-package-img-overlay">
                      <h6>Explore &amp; Adventure</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="package-incluision-box">
                    <div className="inner-package-date">
                      <span>5 Night / 6 Days</span>
                    </div>
                    <div className="inner-package-name">
                      <h4>Royal Rajasthan Tour Package</h4>
                    </div>
                    <div className="inner-package-dest">
                      <p>
                        <b> Destination: </b> Arrive
                        Jaipur-Ajmer-pushkar-jodhpur-Mount Abu-Udaipur Departure{" "}
                      </p>
                    </div>
                    <div className="inner-package-rating">
                      <span>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star half-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        (1,355 reviews)
                      </span>
                    </div>
                    <div className="package-itenary">
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/lunch.png" alt="" />
                          <div className="itneray-name">
                            <span>meals</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/bed.png" alt="" />
                          <div className="itneray-name">
                            <span>Hotel</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/tranfer.png" alt="" />
                          <div className="itneray-name">
                            <span>Transfer</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/sightdeeing.png" alt="" />
                          <div className="itneray-name">
                            <span>Sightseeing</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="package-pricing-box">
                    <div className="del-price">
                      <del>
                        {" "}
                        <h4>
                          <i className="fa fa-inr" /> 32,790
                        </h4>{" "}
                      </del>
                    </div>
                    <div className="main-price">
                      <h4>
                        <i className="fa fa-inr" /> 24,790
                      </h4>
                    </div>
                    <div className="price-para">
                      <span> Per Person On Twin Sharing</span>
                    </div>
                    <div className="buttons-group">
                      <div className="deatails-btn">
                        <a
                          href="fixed-departures-inner.html"
                          className="btn btn-details"
                        >
                          {" "}
                          View Details
                        </a>
                        <a
                          href="check-out-page.html"
                          className="btn btn-enquiry"
                        >
                          {" "}
                          Book Now
                        </a>
                      </div>
                      <div className="contact-btn">
                        <button className="btn btn-call"> Call</button>
                        <button className="btn btn-whatsapp"> Whatsapp</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="inner-page-box">
              <div className="row">
                <div className="col-md-4">
                  <div className="inner-packages-image">
                    <div className="promotion-tag">
                      <span>Promotion</span>
                    </div>
                    <img
                      src="assets/images/inner-package/1-inner-page.jpg"
                      alt=""
                      width="100%"
                    />
                    <div className="inner-package-img-overlay">
                      <h6>Explore &amp; Adventure</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="package-incluision-box">
                    <div className="inner-package-date">
                      <span>5 Night / 6 Days</span>
                    </div>
                    <div className="inner-package-name">
                      <h4>Royal Rajasthan Tour Package</h4>
                    </div>
                    <div className="inner-package-dest">
                      <p>
                        <b> Destination: </b> Arrive
                        Jaipur-Ajmer-pushkar-jodhpur-Mount Abu-Udaipur Departure{" "}
                      </p>
                    </div>
                    <div className="inner-package-rating">
                      <span>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star half-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        (1,355 reviews)
                      </span>
                    </div>
                    <div className="package-itenary">
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/lunch.png" alt="" />
                          <div className="itneray-name">
                            <span>meals</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/bed.png" alt="" />
                          <div className="itneray-name">
                            <span>Hotel</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/tranfer.png" alt="" />
                          <div className="itneray-name">
                            <span>Transfer</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/sightdeeing.png" alt="" />
                          <div className="itneray-name">
                            <span>Sightseeing</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="package-pricing-box">
                    <div className="del-price">
                      <del>
                        {" "}
                        <h4>
                          <i className="fa fa-inr" /> 32,790
                        </h4>{" "}
                      </del>
                    </div>
                    <div className="main-price">
                      <h4>
                        <i className="fa fa-inr" /> 24,790
                      </h4>
                    </div>
                    <div className="price-para">
                      <span> Per Person On Twin Sharing</span>
                    </div>
                    <div className="buttons-group">
                      <div className="deatails-btn">
                        <a
                          href="fixed-departures-inner.html"
                          className="btn btn-details"
                        >
                          {" "}
                          View Details
                        </a>
                        <a
                          href="check-out-page.html"
                          className="btn btn-enquiry"
                        >
                          {" "}
                          Book Now
                        </a>
                      </div>
                      <div className="contact-btn">
                        <button className="btn btn-call"> Call</button>
                        <button className="btn btn-whatsapp"> Whatsapp</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="inner-page-box">
              <div className="row">
                <div className="col-md-4">
                  <div className="inner-packages-image">
                    <div className="promotion-tag">
                      <span>Promotion</span>
                    </div>
                    <img
                      src="assets/images/inner-package/1-inner-page.jpg"
                      alt=""
                      width="100%"
                    />
                    <div className="inner-package-img-overlay">
                      <h6>Explore &amp; Adventure</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="package-incluision-box">
                    <div className="inner-package-date">
                      <span>5 Night / 6 Days</span>
                    </div>
                    <div className="inner-package-name">
                      <h4>Royal Rajasthan Tour Package</h4>
                    </div>
                    <div className="inner-package-dest">
                      <p>
                        <b> Destination: </b> Arrive
                        Jaipur-Ajmer-pushkar-jodhpur-Mount Abu-Udaipur Departure{" "}
                      </p>
                    </div>
                    <div className="inner-package-rating">
                      <span>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        <div className="inner-star half-star">
                          <img src="assets/images/icons/star.svg" alt="" />
                        </div>
                        (1,355 reviews)
                      </span>
                    </div>
                    <div className="package-itenary">
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/lunch.png" alt="" />
                          <div className="itneray-name">
                            <span>meals</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/bed.png" alt="" />
                          <div className="itneray-name">
                            <span>Hotel</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/tranfer.png" alt="" />
                          <div className="itneray-name">
                            <span>Transfer</span>
                          </div>
                        </div>
                      </div>
                      <div className="itneary-box">
                        <div className="itneray-img">
                          <img src="assets/images/icons/sightdeeing.png" alt="" />
                          <div className="itneray-name">
                            <span>Sightseeing</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="package-pricing-box">
                    <div className="del-price">
                      <del>
                        {" "}
                        <h4>
                          <i className="fa fa-inr" /> 32,790
                        </h4>{" "}
                      </del>
                    </div>
                    <div className="main-price">
                      <h4>
                        <i className="fa fa-inr" /> 24,790
                      </h4>
                    </div>
                    <div className="price-para">
                      <span> Per Person On Twin Sharing</span>
                    </div>
                    <div className="buttons-group">
                      <div className="deatails-btn">
                        <a
                          href="fixed-departures-inner.html"
                          className="btn btn-details"
                        >
                          {" "}
                          View Details
                        </a>
                        <a
                          href="check-out-page.html"
                          className="btn btn-enquiry"
                        >
                          {" "}
                          Book Now
                        </a>
                      </div>
                      <div className="contact-btn">
                        <button className="btn btn-call"> Call</button>
                        <button className="btn btn-whatsapp"> Whatsapp</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagignation-box">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item ">
                    <a className="page-link end" href="#">
                      <i className="fa fa-angle-left" />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      ....
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fa fa-angle-right" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}
