import React from 'react'
import Slider from './Slider'

export default function Home() {
  return (
    <>
        <Slider/>
        <section className="destination">
    <div className="container-fluid">
      <div className="title">
        <h2>Discover The India With Us</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="destionation-boxes mt-5">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="listing-page.html">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/1.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Himachal </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/2.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Goa </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/3.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Uttarakhand </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/4.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Rajsthan </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/5.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> South India</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/6.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Kerala</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/7.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Uttar Pradesh</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/8.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Gujarat</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/9.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Kashmir </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/10.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Bihar</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/11.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> North East </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="destionation-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/domastic-destination/12.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="destionation-text">
                  <h5> Tamil Nadu </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="view-more-btn">
          <a className="btn btn-view" href="india-tour.html">
            View More <i className="fa fa-arrow-right" />
          </a>
        </div>
      </div>
    </div>
  </section>
  {/* destination area start  */}
  {/*international destination area start  */}
  <section className="destination-international">
    <div className="container-fluid">
      <div className="title">
        <h2>Discover The World With Us</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="intern-boxes mt-5">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/1.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Thailand </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/2.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Singapore </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/3.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Bali </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/4.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Dubai </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/5.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Maldives</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/6.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Malaysia</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="destination-banner mt-3">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div className="destionation-banner">
                <img
                  src="assets/images/destionation-banners/1.PNG"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div className="destionation-banner">
                <img
                  src="assets/images/destionation-banners/2.PNG"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div className="destionation-banner">
                <img
                  src="assets/images/destionation-banners/1.PNG"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div className="destionation-banner">
                <img
                  src="assets/images/destionation-banners/2.PNG"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* destination area start  */}
  {/* special travels area start  */}
  <section className="special-travels pt-5 pb-5">
    <div className="container-fluid">
      <div className="title">
        <h2>Our Travel Specialization</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="row mt-5">
        <div className="col-lg-8 col-md-12 col-12">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/1.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Honeymoon Tour</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/2.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Leasure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/3.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Trekking</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/4.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Adventure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/5.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Hill Station</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/6.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Yoga</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div className="special-banner">
            <div className="slider">
              <div className="owl-carousel owl-theme" id="owl-special-banner">
                <div className="item">
                  <img src="assets/images/b-1.jpg" alt="" width="100%" />
                </div>
                <div className="item">
                  <img src="assets/images/b-2.jpg" alt="" width="100%" />
                </div>
                <div className="item">
                  <img src="assets/images/b-3.jpg" alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* special travels area end  */}
  {/* popular tour area start  */}
  <section className="popular-tour pt-5 pb-5">
    <div className="container-fluid">
      <div className="title">
        <h2>Our Most Popular Tours</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="row pt-5">
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/1.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/5.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/9.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 ">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/2.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/3.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/4.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/6.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/7.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/8.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/10.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/11.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/12.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
  {/* popular tour area end  */}
  {/* Why Book Are Start  */}
  <section className="why-book py-5">
    <div className="container-fluid">
      <div className="title">
        <h2>Why Book With Us</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="row mt-5">
        <div className="col-lg-6">
          <div className="features">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className="why-book-box box-1" id="box-1">
                  <div className="why-icon">
                    <img src="assets/images/icons/world-wide.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>World Wide Coverage</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="why-book-box box-2" id="box-2">
                  <div className="why-icon">
                    <img src="assets/images/icons/compititive-price.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Competitive Pricing</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="why-book-box" id="box-3">
                  <div className="why-icon">
                    <img src="assets/images/icons/calendar.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Fast Booking</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="why-book-box" id="box-4">
                  <div className="why-icon">
                    <img src="assets/images/icons/support.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Best Support 24/7</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="why-details">
            <span>Our Experience</span>
            <h3>We Are The Best For Providing The Travel Arrangement</h3>
            <p className="pb-3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
              unde, minima possimus nemo voluptates laudantium eveniet fuga
              ipsum quia molestiae, ut maiores consectetur obcaecati nobis!
            </p>
            <hr />
          </div>
          <div className="why-features row py-3">
            <div className="features-box col-4">
              <div className="features-box-icon">
                <img src="assets/images/icons/traveler.png" alt="" width="45px" />
              </div>
              <div className="features-box-name pl-2">
                <h4>60K+</h4>
                <span>Happy Traveler</span>
              </div>
            </div>
            <div className="features-box col-4" id="features-box-border">
              <div className="features-box-icon">
                <img src="assets/images/icons/loyalty.png" alt="" width="45px" />
              </div>
              <div className="features-box-name pl-2">
                <h4>60K+</h4>
                <span>Tours Success</span>
              </div>
            </div>
            <div className="features-box col-4">
              <div className="features-box-icon">
                <img src="assets/images/icons/experience.png" alt="" width="45px" />
              </div>
              <div className="features-box-name pl-2">
                <h4>60K+</h4>
                <span>Positives Review </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="last-line">
            <p className="text-center">
              <b>Excellent!</b>{" "}
              <span>
                {" "}
                <i className="fa fa-circle" /> <i className="fa fa-circle" />{" "}
                <i className="fa fa-circle" /> <i className="fa fa-circle" />
                <i className="fa fa-circle-o" />{" "}
              </span>{" "}
              4.5 Rating Out of 5.0 Based On 526 reviews.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Why Book Are Start  */}
  {/* testimonial section start here  */}
  <section className="testimonials py-5">
    <div className="container-fluid">
      <div className="title">
        <h2>Customers Feedback</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="slider mt-5">
        <div className="owl-carousel owl-theme" id="owl-testimonial">
          <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  {/* Modal */}
  <div
    className="modal fade"
    id="exampleModal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header" id="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel" />
          <button
            type="button"
            className="btn-close modal-button-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="row">
          <div className="col-lg-6" id="mobile-modal-hide">
            <div className="modal-body">
              <div className="modal-banner-content">
                <div className="modal-banner">
                  <div
                    id="carouselExampleRide"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                    data-interval={3000}
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/1.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                      <div className="carousel-item">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/2.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                      <div className="carousel-item">
                        <a href="#">
                          <img
                            src="assets/images/modal-banner/3.jpg"
                            className="d-block w-100"
                            alt="..."
                          />
                        </a>
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleRide"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleRide"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <div className="modal-features">
                  <div className="why-features row py-3">
                    <div className="features-box col-4">
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/traveler.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Happy Traveler</span>
                      </div>
                    </div>
                    <div
                      className="features-box col-4"
                      id="features-box-border"
                    >
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/loyalty.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Tours Success</span>
                      </div>
                    </div>
                    <div className="features-box col-4">
                      <div className="features-box-icon">
                        <img
                          src="assets/images/icons/experience.png"
                          alt=""
                          width="45px"
                        />
                      </div>
                      <div className="features-box-name pl-2">
                        <h4>60K+</h4>
                        <span>Positives Review </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="last-line">
                    <p className="text-center">
                      <b>Excellent!</b>{" "}
                      <span>
                        {" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle" />{" "}
                        <i className="fa fa-circle-o" />{" "}
                      </span>{" "}
                      4.5 Rating Out of 5.0 Based On 526 reviews.
                    </p>
                  </div>
                </div>
                <div className="modal-contact">
                  <div className="call-modal">
                    <h6>
                      <a href="">
                        <span>
                          <i className="fa fa-phone" /> Call Us :{" "}
                        </span>{" "}
                        +91-1234567890
                      </a>
                    </h6>
                    <h6>
                      <a href="">
                        <span>
                          <i className="fa fa-envelope" /> Send Us Mail :{" "}
                        </span>{" "}
                        info@mintoholidays.com
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6" id="pop-right">
            <div className="modal-body" id="right-body">
              <div className="popup-form">
                <h5>Get in touch with us</h5>
                <form action="">
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Enter Phone no."
                    />
                  </div>
                  <div className="col mb-3">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Email Id."
                    />
                  </div>
                  <div className="col mb-3">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      placeholder="Enter Tour Details"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col- mb-3">
                    <button className="btn btn-submit">Submit Enquiry</button>
                    {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                  </div>
                </form>
              </div>
              <div className="get-whatsapp">
                <a href="">
                  <span>
                    <img src="assets/images/icons/whatsapp.png" alt="" />
                  </span>
                  <h6>Get itenary On Whatsapp</h6>
                </a>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* floating-icons  */}
  <div className="quick_contact">
    <a
      href="https://api.whatsapp.com/send?phone=#&text=hello"
      className="qwhtsapp"
      target="_blank"
    >
      <i className="fa fa-whatsapp quick_contact_icon" />
    </a>
    <a href="#" className="qcall" target="_blank">
      <i className="fa fa-phone quick_contact_icon" />
    </a>
  </div>
  <div className="qiuck-enquiry">
    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
      Enquiry Now
    </button>
  </div>
    </>
  )
}
