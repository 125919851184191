import { BrowserRouter as Router, useLocation } from "react-router-dom";
import './App.css';
import AppRoutes from './routes/routes';
import Header from "./component/common/Header";
import Footer from "./component/common/Footer";

function Layout({ children }) {
  const location = useLocation();
  return (
    <>
    <Header /> 
    {children}
    <Footer/>
    </>
  );
}

function App() {
  return (
  
<Router basename="/">
      <Layout>
        <AppRoutes />
      </Layout>
    </Router>
  );
}

export default App;
