import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../component/home/Home';
import About from '../component/about/About';
import IndiaTour from '../component/india-tour/IndiaTour';
import InternationalTour from '../component/international-tour/InternationalTour';
import ThemeBased from '../component/theme-based/ThemeBased';
import FixedDepartures from '../component/fixed-departures/FixedDepartures';
import Contact from '../component/contact/Contact';
import GuestFeedback from '../component/feedback/GuestFeedback';
import Payment from '../component/payment/Payment';

const AppRoutes = () => {
  return (
    <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/about-us" element={<About />} />
       <Route path="/india-tour" element={<IndiaTour />} />
       <Route path="/international-tour" element={<InternationalTour />} />
       <Route path="/theme-base" element={<ThemeBased />} />
       <Route path='/fixed-departures' element={<FixedDepartures/>}/> 
       <Route path='/contact-us' element={<Contact/>}/>
       <Route path='/guest-feedback' element={<GuestFeedback/>}/>  
       <Route path='/payment' element={<Payment/>}/>     
      {/* <Route path="*" element={<NotFound/>}/> */}
    </Routes>
  );
}

export default AppRoutes;
