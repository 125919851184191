import React from 'react'

export default function InternationalTour() {
  return (
    <>
  {/* banner section  */}
  <section className="banner-section">
    <div className="banner">
      <img src="assets/images/international-tour-banner.jpg" alt="" width="100%" />
    </div>
    {/* search form start  */}
    <div className="search-form">
      <div className="search-form-box">
        <div className="form">
          <div className="form-inputs">
            <div className="search-form-input-1" id="first-select">
              <i className="fa fa-map-marker" />
              <select id="Cityselect">
                <option value="disable">Select City</option>
                <option value="AL">Uttarakhand</option>
                <option value="WY">Himachal</option>
                <option value="WY">Kashmir</option>
                <option value="WY">Kerala</option>
                <option value="WY">Goa</option>
                <option value="WY">Rajasthan</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="search-form-input-2" id="second-select">
              <i className="fa fa-flag-o" />
              <select id="Tourselect">
                <option value="disable">Select Your Tour</option>
                <option value="AL">Honeymoon Tour Package</option>
                <option value="WY">Car Tour Package</option>
                <option value="WY">Couple Tour package</option>
                <option value="WY">Family Tour Package</option>
                <option value="WY">Group Tour Package</option>
                {/* Add more options as needed */}
              </select>
            </div>
            <div className="search-form-button">
              <button className="btn btn-search text-light">
                Search <i className="fa fa-send-o" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* search form end  */}
  </section>
  {/* banner section end  */}
  {/*international destination area start  */}
  <section
    className="destination-international"
    style={{ backgroundColor: "#f2f5ec" }}
  >
    <div className="container-fluid">
      <div className="title">
        <h2>Discover The World With Us</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="intern-boxes mt-5">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/1.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Thailand </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/2.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Singapore </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/3.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Bali </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/4.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Dubai </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/5.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Maldives</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/6.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Malaysia</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* destination area start  */}
  {/* special travels area start  */}
  <section className="special-travels pt-5 pb-5">
    <div className="container-fluid">
      <div className="title">
        <h2>Our Travel Specialization</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="row mt-5">
        <div className="col-lg-8 col-md-12 col-12">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/1.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Honeymoon Tour</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/2.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Leasure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/3.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Trekking</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/4.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Adventure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/5.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Hill Station</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/6.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Yoga</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div className="special-banner">
            <div className="slider">
              <div className="owl-carousel owl-theme" id="owl-special-banner">
                <div className="item">
                  <img src="assets/images/b-1.jpg" alt="" width="100%" />
                </div>
                <div className="item">
                  <img src="assets/images/b-2.jpg" alt="" width="100%" />
                </div>
                <div className="item">
                  <img src="assets/images/b-3.jpg" alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* special travels area end  */}
  {/* popular tour area start  */}
  <section className="popular-tour pt-5 pb-5">
    <div className="container-fluid">
      <div className="title">
        <h2>Our Most Popular Tours</h2>
        <p>Whatever you're looking for places for a vacation. We are here to</p>
      </div>
      <div className="row pt-5">
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/1.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/5.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/9.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 ">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/2.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/3.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/4.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/6.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/7.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/8.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/10.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 ">
                  <div className="row popular-packages-box m-1 last-box-2">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/11.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-4 col-12 mb-3">
          <a href="#">
            <div className="popular-packages py-2 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="row popular-packages-box m-1 last-box">
                    <div className="col-4">
                      <div className="popular-package-img">
                        <img
                          src="assets/images/popular-packages/12.jpg"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="popular-package-text">
                        <h5>Manali Volvo Tour Package</h5>
                        <span>4 Night - 5 Days</span>
                        <p>Volvo Ticket, Hotel, Meals, Private cab</p>
                        <h4>
                          <i className="fa fa-inr" /> 3999/-{" "}
                          <span>Per Person</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
  {/* popular tour area end  */}
</>

  )
}
